import React, { useEffect, useLayoutEffect } from 'react'

import { useForm } from '../../hooks/useForm'
import Carousels from '../../components/carousel'
import Layout from '../../components/layout'
import Pager from '../../components/pager'
import Seo from '../../components/seo'
// import pictureFeedNews from '../../images/notices.jpeg'
import logo from '../../images/newspaper.png'
import { graphql, Link, navigate } from 'gatsby'
import { useWindowSize } from '../../hooks/useWindowSize'
import urlSlug from 'url-slug'
import { NEWS_GENERAL_ROUTE } from '../../utils/variables/generalRoutes'
import moment from 'moment'
import { validateNews } from '../../helpers/validateNews'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons'

const newList = ({ data, pageContext }) => {
  const { mainNavbar, noveltiesCategoriesData, category } = pageContext

  const {
    allStrapiNovelties: { nodes: novelties },
    strapiInstitution: institution
  } = data

  const page = {
    slug: null,
    ...mainNavbar
  }

  const [width] = useWindowSize()

  const [valueCategory, handleInputChange] = useForm({
    categorySelected: category
  })

  const { categorySelected } = valueCategory

  // TODO ver de modularizar esto que se repite mucho
  const objCarousel = {
    visible: true,
    slide: institution.mainCover?.noveltiesCover
  }

  useEffect(() => {
    if (categorySelected.toLowerCase() !== category.toLowerCase()) {
      if (categorySelected.toLowerCase() === 'todas') {
        return navigate(`/${NEWS_GENERAL_ROUTE}/`, { replace: false })
      }
      navigate(`/${NEWS_GENERAL_ROUTE}/${urlSlug(categorySelected)}`, {
        replace: true
      })
    }
  }, [categorySelected])

  useLayoutEffect(() => {
    if (width < 768) {
      return window.scrollTo({ top: 265, behavior: 'smooth' })
    } else if (width < 1400) {
      return window.scrollTo({ top: 270, behavior: 'smooth' })
    } else if (width < 1600) {
      return window.scrollTo({ top: 304, behavior: 'smooth' })
    } else {
      return window.scrollTo({ top: 370, behavior: 'smooth' })
    }
  }, [width])

  const handleClickViewAllUcc = () => {
    navigate(`/${NEWS_GENERAL_ROUTE}/`, {
      replace: false
    })
  }

  return data ? (
    <Layout page={page} logo={page.logo}>
      <Seo title={`Noticias ${mainNavbar.name || ''}`} />
      <section className="schedule-feed mb-3">
        {objCarousel?.slide && objCarousel?.slide.length > 0 ? (
          <div className="d-flex flex-column schedule-header-carousel">
            <Carousels carousel={objCarousel} keyName={'newsFeed-'} />
          </div>
        ) : null}

        <div className="container">
          <div className="my-5">
            <div className="row d-flex align-items-center mb-3 row">
              <div className="col-12 col-md-8">
                <div className=" border-bottom">
                  <h5 className="text-uppercase title-secondary">
                    <FontAwesomeIcon icon={faNewspaper} className="mr-2" />
                    Noticias{' '}
                    <span className="font-weight-bold text-main pb-1">
                      {category}
                    </span>
                  </h5>
                </div>
              </div>
              <form className="col-12 col-md-4 mt-separate">
                {' '}
                <div className="d-flex">
                  <select
                    className="form-control form-control-sm"
                    onChange={handleInputChange}
                    name="categorySelected"
                    defaultValue={categorySelected}
                  >
                    <option value="-1" disabled>
                      Por Categoría
                    </option>
                    <option value="Todas">Todas</option>
                    {noveltiesCategoriesData.map((category) => (
                      <option value={category.name} key={category.strapiId}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
              <div className="col-12">
                <div className="d-flex justify-content-end mt-3">
                  <button
                    className="btn btn-sm ucc-background-color border-ucc-boton"
                    onClick={handleClickViewAllUcc}
                  >
                    Ver todas UCC
                  </button>
                </div>
              </div>
            </div>
            <div className="row">
              {novelties.length < 1 || validateNews(novelties) ? (
                <h4 className="mt-5 col">
                  No se encontraron noticias de la UCC con la categoría
                  {` "${categorySelected}"`}
                </h4>
              ) : (
                novelties.map((news) => {
                  if (
                    news.dateTo === null ||
                    moment(news.dateTo, 'YYYY/MM/DD').isSameOrAfter(moment())
                  )
                    return (
                      <div
                        className={`mb-4 col-12 col-md-4 'hightlighted'
                    `}
                        key={`noveltiesNodesId-${news.strapiId}`}
                      >
                        <div className="h-100 d-flex flex-column rounded shadow-sm news-card overflow-hidden position-relative">
                          <div
                            className="news-image"
                            style={{
                              backgroundImage: `url(
                              ${news.thumbnail ? news.thumbnail.url : logo} 
                            )`
                            }}
                          ></div>
                          <div className="flex-grow-1 p-4 d-flex flex-column news-info">
                            <h6>
                              <small>
                                {' '}
                                {moment(news.date)
                                  .locale('es')
                                  .format('DD-MM-YYYY')}
                              </small>
                            </h6>

                            {/* Etiqueta de la noticia - novelties categories */}
                            {/* <div className="bg-sec px-2 py-1 text-white mb-2 fit-content">
                              {news.novelties_categories?.map(
                                (noveltieCategory, i) => {
                                  if (
                                    news.novelties_categories.length - 1 >
                                    i
                                  ) {
                                    return noveltieCategory?.name + ' - '
                                  } else {
                                    return noveltieCategory?.name
                                  }
                                }
                              )}
                            </div> */}

                            <h5 className="font-weight-bold">{news.title}</h5>
                            <div className="noticies-markdown markdown-format">
                              <p>{news.summary}</p>
                            </div>

                            <Link
                              to={`/${NEWS_GENERAL_ROUTE}/${news.slug}`}
                              className={`btn btn-outline-info mt-auto fit-content text-uppercase 
                                rounded-pill px-4 font-secondary eventuality-button-font-size
                              }`}
                            >
                              VER MÁS
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                })
              )}
            </div>
            <Pager pageContext={pageContext} />
          </div>
        </div>
      </section>
    </Layout>
  ) : null
}

export default newList

export const newListQuery = graphql`
  query($skip: Int, $limit: Int, $date: Date, $category: String) {
    allStrapiNovelties(
      skip: $skip
      limit: $limit
      sort: { fields: date, order: DESC }
      filter: {
        date: { lte: $date }
        novelties_categories: { elemMatch: { name: { eq: $category } } }
        uccGeneral: { eq: true }
      }
    ) {
      nodes {
        strapiId
        title
        slug
        summary
        date
        dateTo
        thumbnail {
          url
        }
        novelties_categories {
          name
        }
      }
    }
    strapiInstitution {
      name
      mainCover {
        noveltiesCover {
          content
          imageDescription
          name
          url
          visible
          backgroundImage {
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
      }
    }
  }
`
